/* Colors */
@font-face {
  font-family: Campton;
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-light-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-light-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-light-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: bold;
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-bold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-bold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-bold-webfont.woff") format("woff");
}
@font-face {
  font-family: Campton;
  font-weight: 600;
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot");
  src: url("/Themes/comeos/fonts/campton-semibold-webfont.eot?#iefix") format("embedded-opentype"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff2") format("woff2"), url("/Themes/comeos/fonts/campton-semibold-webfont.woff") format("woff");
}
.altai-theme-content #bodyView,
.altai-theme-admin {
  /*!
 * FullCalendar v2.7.3 Stylesheet
 * Docs & License: http://fullcalendar.io/
 * (c) 2016 Adam Shaw
 */
  /* Colors
--------------------------------------------------------------------------------------------------*/
  /* Icons (inline elements with styled text that mock arrow icons)
--------------------------------------------------------------------------------------------------*/
  /*
Acceptable font-family overrides for individual icons:
	"Arial", sans-serif
	"Times New Roman", serif

NOTE: use percentage font sizes or else old IE chokes
*/
  /* Buttons (styled <button> tags, normalized to work cross-browser)
--------------------------------------------------------------------------------------------------*/
  /* Firefox has an annoying inner border */
  /* icons in buttons */
  /*
  button states
  borrowed from twitter bootstrap (http://twitter.github.com/bootstrap/)
*/
  /* Buttons Groups
--------------------------------------------------------------------------------------------------*/
  /*
every button that is not first in a button group should scootch over one pixel and cover the
previous button's border...
*/
  /* Popover
--------------------------------------------------------------------------------------------------*/
  /* unthemed */
  /* jqui themed */
  /* Misc Reusable Components
--------------------------------------------------------------------------------------------------*/
  /* Tables
--------------------------------------------------------------------------------------------------*/
  /* Fake Table Rows
--------------------------------------------------------------------------------------------------*/
  /* Day Row (used within the header and the DayGrid)
--------------------------------------------------------------------------------------------------*/
  /* highlighting cells & background event skeleton */
  /*
row content (which contains day/week numbers and events) as well as "helper" (which contains
temporary rendered events).
*/
  /* Scrolling Container
--------------------------------------------------------------------------------------------------*/
  /* TODO: move to agenda/basic */
  /* Global Event Styles
--------------------------------------------------------------------------------------------------*/
  /* overpower some of bootstrap's and jqui's styles on <a> tags */
  /* resizer (cursor AND touch devices) */
  /* resizer (touch devices) */
  /* hit area */
  /* Event Selection (only for touch devices)
--------------------------------------------------------------------------------------------------*/
  /* Horizontal Events
--------------------------------------------------------------------------------------------------*/
  /* bigger touch area when selected */
  /* events that are continuing to/from another week. kill rounded corners and butt up against edge */
  /* resizer (cursor AND touch devices) */
  /* left resizer  */
  /* right resizer */
  /* resizer (mouse devices) */
  /* resizer (touch devices) */
  /* left resizer  */
  /* right resizer */
  /* DayGrid events
----------------------------------------------------------------------------------------------------
We use the full "fc-day-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
  /* resizer (cursor devices) */
  /* left resizer  */
  /* right resizer */
  /* Event Limiting
--------------------------------------------------------------------------------------------------*/
  /* "more" link that represents hidden events */
  /* popover that appears when "more" link is clicked */
  /* Now Indicator
--------------------------------------------------------------------------------------------------*/
  /* Utilities
--------------------------------------------------------------------------------------------------*/
  /* Toolbar
--------------------------------------------------------------------------------------------------*/
  /* the things within each left/right/center section */
  /* the first thing within each left/center/right section */
  /* title text */
  /* button layering (for border precedence) */
  /* View Structure
--------------------------------------------------------------------------------------------------*/
  /* undo twitter bootstrap's box-sizing rules. normalizes positioning techniques */
  /* don't do this for the toolbar because we'll want bootstrap to style those buttons as some pt */
  /* BasicView
--------------------------------------------------------------------------------------------------*/
  /* day row structure */
  /* a "rigid" row will take up a constant amount of height because content-skeleton is absolute */
  /* week and day number styling */
  /* AgendaView all-day area
--------------------------------------------------------------------------------------------------*/
  /* TimeGrid axis running down the side (for both the all-day area and the slot area)
--------------------------------------------------------------------------------------------------*/
  /* TimeGrid Structure
--------------------------------------------------------------------------------------------------*/
  /* divs within a cell within the fc-content-skeleton */
  /* TimeGrid Slats (lines that run horizontally)
--------------------------------------------------------------------------------------------------*/
  /* TimeGrid Highlighting Slots
--------------------------------------------------------------------------------------------------*/
  /* TimeGrid Event Containment
--------------------------------------------------------------------------------------------------*/
  /* Generic Vertical Event
--------------------------------------------------------------------------------------------------*/
  /* TimeGrid Event Styling
----------------------------------------------------------------------------------------------------
We use the full "fc-time-grid-event" class instead of using descendants because the event won't
be a descendant of the grid when it is being dragged.
*/
  /* short mode, where time and title are on the same line */
  /* resizer (cursor device) */
  /* resizer (touch device) */
  /* Now Indicator
--------------------------------------------------------------------------------------------------*/
  /* arrow on axis */
  /**Adjustments Jan Material from here*/
}
.altai-theme-content #bodyView .fc,
.altai-theme-admin .fc {
  direction: ltr;
  text-align: left;
  /* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
  /* HTML5 display-role reset for older browsers */
}
.altai-theme-content #bodyView .fc html,
.altai-theme-admin .fc html,
.altai-theme-content #bodyView .fc body,
.altai-theme-admin .fc body,
.altai-theme-content #bodyView .fc div,
.altai-theme-admin .fc div,
.altai-theme-content #bodyView .fc span,
.altai-theme-admin .fc span,
.altai-theme-content #bodyView .fc applet,
.altai-theme-admin .fc applet,
.altai-theme-content #bodyView .fc object,
.altai-theme-admin .fc object,
.altai-theme-content #bodyView .fc iframe,
.altai-theme-admin .fc iframe,
.altai-theme-content #bodyView .fc h1,
.altai-theme-admin .fc h1,
.altai-theme-content #bodyView .fc h2,
.altai-theme-admin .fc h2,
.altai-theme-content #bodyView .fc h3,
.altai-theme-admin .fc h3,
.altai-theme-content #bodyView .fc h4,
.altai-theme-admin .fc h4,
.altai-theme-content #bodyView .fc h5,
.altai-theme-admin .fc h5,
.altai-theme-content #bodyView .fc h6,
.altai-theme-admin .fc h6,
.altai-theme-content #bodyView .fc p,
.altai-theme-admin .fc p,
.altai-theme-content #bodyView .fc blockquote,
.altai-theme-admin .fc blockquote,
.altai-theme-content #bodyView .fc pre,
.altai-theme-admin .fc pre,
.altai-theme-content #bodyView .fc a,
.altai-theme-admin .fc a,
.altai-theme-content #bodyView .fc abbr,
.altai-theme-admin .fc abbr,
.altai-theme-content #bodyView .fc acronym,
.altai-theme-admin .fc acronym,
.altai-theme-content #bodyView .fc address,
.altai-theme-admin .fc address,
.altai-theme-content #bodyView .fc big,
.altai-theme-admin .fc big,
.altai-theme-content #bodyView .fc cite,
.altai-theme-admin .fc cite,
.altai-theme-content #bodyView .fc code,
.altai-theme-admin .fc code,
.altai-theme-content #bodyView .fc del,
.altai-theme-admin .fc del,
.altai-theme-content #bodyView .fc dfn,
.altai-theme-admin .fc dfn,
.altai-theme-content #bodyView .fc em,
.altai-theme-admin .fc em,
.altai-theme-content #bodyView .fc img,
.altai-theme-admin .fc img,
.altai-theme-content #bodyView .fc ins,
.altai-theme-admin .fc ins,
.altai-theme-content #bodyView .fc kbd,
.altai-theme-admin .fc kbd,
.altai-theme-content #bodyView .fc q,
.altai-theme-admin .fc q,
.altai-theme-content #bodyView .fc s,
.altai-theme-admin .fc s,
.altai-theme-content #bodyView .fc samp,
.altai-theme-admin .fc samp,
.altai-theme-content #bodyView .fc small,
.altai-theme-admin .fc small,
.altai-theme-content #bodyView .fc strike,
.altai-theme-admin .fc strike,
.altai-theme-content #bodyView .fc strong,
.altai-theme-admin .fc strong,
.altai-theme-content #bodyView .fc sub,
.altai-theme-admin .fc sub,
.altai-theme-content #bodyView .fc sup,
.altai-theme-admin .fc sup,
.altai-theme-content #bodyView .fc tt,
.altai-theme-admin .fc tt,
.altai-theme-content #bodyView .fc var,
.altai-theme-admin .fc var,
.altai-theme-content #bodyView .fc b,
.altai-theme-admin .fc b,
.altai-theme-content #bodyView .fc u,
.altai-theme-admin .fc u,
.altai-theme-content #bodyView .fc i,
.altai-theme-admin .fc i,
.altai-theme-content #bodyView .fc center,
.altai-theme-admin .fc center,
.altai-theme-content #bodyView .fc dl,
.altai-theme-admin .fc dl,
.altai-theme-content #bodyView .fc dt,
.altai-theme-admin .fc dt,
.altai-theme-content #bodyView .fc dd,
.altai-theme-admin .fc dd,
.altai-theme-content #bodyView .fc ol,
.altai-theme-admin .fc ol,
.altai-theme-content #bodyView .fc ul,
.altai-theme-admin .fc ul,
.altai-theme-content #bodyView .fc li,
.altai-theme-admin .fc li,
.altai-theme-content #bodyView .fc fieldset,
.altai-theme-admin .fc fieldset,
.altai-theme-content #bodyView .fc form,
.altai-theme-admin .fc form,
.altai-theme-content #bodyView .fc label,
.altai-theme-admin .fc label,
.altai-theme-content #bodyView .fc legend,
.altai-theme-admin .fc legend,
.altai-theme-content #bodyView .fc table,
.altai-theme-admin .fc table,
.altai-theme-content #bodyView .fc caption,
.altai-theme-admin .fc caption,
.altai-theme-content #bodyView .fc tbody,
.altai-theme-admin .fc tbody,
.altai-theme-content #bodyView .fc tfoot,
.altai-theme-admin .fc tfoot,
.altai-theme-content #bodyView .fc thead,
.altai-theme-admin .fc thead,
.altai-theme-content #bodyView .fc tr,
.altai-theme-admin .fc tr,
.altai-theme-content #bodyView .fc th,
.altai-theme-admin .fc th,
.altai-theme-content #bodyView .fc td,
.altai-theme-admin .fc td,
.altai-theme-content #bodyView .fc article,
.altai-theme-admin .fc article,
.altai-theme-content #bodyView .fc aside,
.altai-theme-admin .fc aside,
.altai-theme-content #bodyView .fc canvas,
.altai-theme-admin .fc canvas,
.altai-theme-content #bodyView .fc details,
.altai-theme-admin .fc details,
.altai-theme-content #bodyView .fc embed,
.altai-theme-admin .fc embed,
.altai-theme-content #bodyView .fc figure,
.altai-theme-admin .fc figure,
.altai-theme-content #bodyView .fc figcaption,
.altai-theme-admin .fc figcaption,
.altai-theme-content #bodyView .fc footer,
.altai-theme-admin .fc footer,
.altai-theme-content #bodyView .fc header,
.altai-theme-admin .fc header,
.altai-theme-content #bodyView .fc hgroup,
.altai-theme-admin .fc hgroup,
.altai-theme-content #bodyView .fc menu,
.altai-theme-admin .fc menu,
.altai-theme-content #bodyView .fc nav,
.altai-theme-admin .fc nav,
.altai-theme-content #bodyView .fc output,
.altai-theme-admin .fc output,
.altai-theme-content #bodyView .fc ruby,
.altai-theme-admin .fc ruby,
.altai-theme-content #bodyView .fc section,
.altai-theme-admin .fc section,
.altai-theme-content #bodyView .fc summary,
.altai-theme-admin .fc summary,
.altai-theme-content #bodyView .fc time,
.altai-theme-admin .fc time,
.altai-theme-content #bodyView .fc mark,
.altai-theme-admin .fc mark,
.altai-theme-content #bodyView .fc audio,
.altai-theme-admin .fc audio,
.altai-theme-content #bodyView .fc video,
.altai-theme-admin .fc video {
  margin: 0;
  padding: 0;
  border: 0;
  /*font:inherit;*/
  font-size: 100%;
  vertical-align: baseline;
}
.altai-theme-content #bodyView .fc article,
.altai-theme-admin .fc article,
.altai-theme-content #bodyView .fc aside,
.altai-theme-admin .fc aside,
.altai-theme-content #bodyView .fc details,
.altai-theme-admin .fc details,
.altai-theme-content #bodyView .fc figcaption,
.altai-theme-admin .fc figcaption,
.altai-theme-content #bodyView .fc figure,
.altai-theme-admin .fc figure,
.altai-theme-content #bodyView .fc footer,
.altai-theme-admin .fc footer,
.altai-theme-content #bodyView .fc header,
.altai-theme-admin .fc header,
.altai-theme-content #bodyView .fc hgroup,
.altai-theme-admin .fc hgroup,
.altai-theme-content #bodyView .fc menu,
.altai-theme-admin .fc menu,
.altai-theme-content #bodyView .fc nav,
.altai-theme-admin .fc nav,
.altai-theme-content #bodyView .fc section,
.altai-theme-admin .fc section {
  display: block;
}
.altai-theme-content #bodyView .fc body,
.altai-theme-admin .fc body {
  line-height: 1;
}
.altai-theme-content #bodyView .fc ol,
.altai-theme-admin .fc ol,
.altai-theme-content #bodyView .fc ul,
.altai-theme-admin .fc ul {
  list-style: none;
}
.altai-theme-content #bodyView .fc blockquote,
.altai-theme-admin .fc blockquote,
.altai-theme-content #bodyView .fc q,
.altai-theme-admin .fc q {
  quotes: none;
}
.altai-theme-content #bodyView .fc blockquote:before,
.altai-theme-admin .fc blockquote:before,
.altai-theme-content #bodyView .fc blockquote:after,
.altai-theme-admin .fc blockquote:after,
.altai-theme-content #bodyView .fc q:before,
.altai-theme-admin .fc q:before,
.altai-theme-content #bodyView .fc q:after,
.altai-theme-admin .fc q:after {
  content: '';
  content: none;
}
.altai-theme-content #bodyView .fc table,
.altai-theme-admin .fc table {
  border-collapse: collapse;
  border-spacing: 0;
}
.altai-theme-content #bodyView .fc table tr:nth-child(odd),
.altai-theme-admin .fc table tr:nth-child(odd) {
  background: none;
}
.altai-theme-content #bodyView .fc-rtl,
.altai-theme-admin .fc-rtl {
  text-align: right;
}
.altai-theme-content #bodyView body .fc,
.altai-theme-admin body .fc {
  /* extra precedence to overcome jqui */
  font-size: 1em;
}
.altai-theme-content #bodyView .fc-unthemed th,
.altai-theme-admin .fc-unthemed th,
.altai-theme-content #bodyView .fc-unthemed td,
.altai-theme-admin .fc-unthemed td,
.altai-theme-content #bodyView .fc-unthemed thead,
.altai-theme-admin .fc-unthemed thead,
.altai-theme-content #bodyView .fc-unthemed tbody,
.altai-theme-admin .fc-unthemed tbody,
.altai-theme-content #bodyView .fc-unthemed .fc-divider,
.altai-theme-admin .fc-unthemed .fc-divider,
.altai-theme-content #bodyView .fc-unthemed .fc-row,
.altai-theme-admin .fc-unthemed .fc-row,
.altai-theme-content #bodyView .fc-unthemed .fc-content,
.altai-theme-admin .fc-unthemed .fc-content,
.altai-theme-content #bodyView .fc-unthemed .fc-popover,
.altai-theme-admin .fc-unthemed .fc-popover {
  border-color: #ddd;
}
.altai-theme-content #bodyView .fc-unthemed .fc-popover,
.altai-theme-admin .fc-unthemed .fc-popover {
  background-color: #fff;
}
.altai-theme-content #bodyView .fc-unthemed .fc-divider,
.altai-theme-admin .fc-unthemed .fc-divider,
.altai-theme-content #bodyView .fc-unthemed .fc-popover .fc-header,
.altai-theme-admin .fc-unthemed .fc-popover .fc-header {
  background: #eee;
}
.altai-theme-content #bodyView .fc-unthemed .fc-popover .fc-header .fc-close,
.altai-theme-admin .fc-unthemed .fc-popover .fc-header .fc-close {
  color: #666;
}
.altai-theme-content #bodyView .fc-unthemed .fc-today,
.altai-theme-admin .fc-unthemed .fc-today {
  background: #fcf8e3;
}
.altai-theme-content #bodyView .fc-highlight,
.altai-theme-admin .fc-highlight {
  /* when user is selecting cells */
  background: #bce8f1;
  opacity: .3;
  filter: alpha(opacity=30);
  /* for IE */
}
.altai-theme-content #bodyView .fc-bgevent,
.altai-theme-admin .fc-bgevent {
  /* default look for background events */
  background: #8fdf82;
  opacity: .3;
  filter: alpha(opacity=30);
  /* for IE */
}
.altai-theme-content #bodyView .fc-nonbusiness,
.altai-theme-admin .fc-nonbusiness {
  /* default look for non-business-hours areas */
  /* will inherit .fc-bgevent's styles */
  background: #d7d7d7;
}
.altai-theme-content #bodyView .fc-icon,
.altai-theme-admin .fc-icon {
  display: inline-block;
  height: 1em;
  line-height: 1em;
  font-size: 1em;
  text-align: center;
  overflow: hidden;
  font-family: "Courier New", Courier, monospace;
  /* don't allow browser text-selection */
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.altai-theme-content #bodyView .fc-icon:after,
.altai-theme-admin .fc-icon:after {
  position: relative;
}
.altai-theme-content #bodyView .fc-icon-left-single-arrow:after,
.altai-theme-admin .fc-icon-left-single-arrow:after {
  content: "\02039";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
}
.altai-theme-content #bodyView .fc-icon-right-single-arrow:after,
.altai-theme-admin .fc-icon-right-single-arrow:after {
  content: "\0203A";
  font-weight: bold;
  font-size: 200%;
  top: -7%;
}
.altai-theme-content #bodyView .fc-icon-left-double-arrow:after,
.altai-theme-admin .fc-icon-left-double-arrow:after {
  content: "\000AB";
  font-size: 160%;
  top: -7%;
}
.altai-theme-content #bodyView .fc-icon-right-double-arrow:after,
.altai-theme-admin .fc-icon-right-double-arrow:after {
  content: "\000BB";
  font-size: 160%;
  top: -7%;
}
.altai-theme-content #bodyView .fc-icon-left-triangle:after,
.altai-theme-admin .fc-icon-left-triangle:after {
  content: "\25C4";
  font-size: 125%;
  top: 3%;
}
.altai-theme-content #bodyView .fc-icon-right-triangle:after,
.altai-theme-admin .fc-icon-right-triangle:after {
  content: "\25BA";
  font-size: 125%;
  top: 3%;
}
.altai-theme-content #bodyView .fc-icon-down-triangle:after,
.altai-theme-admin .fc-icon-down-triangle:after {
  content: "\25BC";
  font-size: 125%;
  top: 2%;
}
.altai-theme-content #bodyView .fc-icon-x:after,
.altai-theme-admin .fc-icon-x:after {
  content: "\000D7";
  font-size: 200%;
  top: 6%;
}
.altai-theme-content #bodyView .fc button,
.altai-theme-admin .fc button {
  /* force height to include the border and padding */
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  /* dimensions */
  margin: 0;
  height: 2.1em;
  padding: 0 .6em;
  /* text & cursor */
  font-size: 1em;
  /* normalize */
  white-space: nowrap;
  cursor: pointer;
  background-color: transparent !important;
  background-image: none;
}
.altai-theme-content #bodyView .fc button::-moz-focus-inner,
.altai-theme-admin .fc button::-moz-focus-inner {
  margin: 0;
  padding: 0;
}
.altai-theme-content #bodyView .fc-state-default,
.altai-theme-admin .fc-state-default {
  /* non-theme */
  border: 0 solid;
}
.altai-theme-content #bodyView .fc-state-default.fc-corner-left,
.altai-theme-admin .fc-state-default.fc-corner-left {
  /* non-theme */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.altai-theme-content #bodyView .fc-state-default.fc-corner-right,
.altai-theme-admin .fc-state-default.fc-corner-right {
  /* non-theme */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.altai-theme-content #bodyView .fc button .fc-icon,
.altai-theme-admin .fc button .fc-icon {
  /* non-theme */
  position: relative;
  top: -0.05em;
  /* seems to be a good adjustment across browsers */
  margin: 0 .2em;
  vertical-align: middle;
}
.altai-theme-content #bodyView .fc-state-default,
.altai-theme-admin .fc-state-default {
  background-color: #f5f5f5;
  background-image: -moz-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -webkit-gradient(linear, 0 0, 0 100%, from(#ffffff), to(#e6e6e6));
  background-image: -webkit-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: -o-linear-gradient(top, #ffffff, #e6e6e6);
  background-image: linear-gradient(to bottom, #ffffff, #e6e6e6);
  background-repeat: repeat-x;
  border-color: #e6e6e6 #e6e6e6 #bfbfbf;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
  color: #333;
  text-shadow: 0 1px 1px rgba(255, 255, 255, 0.75);
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.2), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.altai-theme-content #bodyView .fc-state-hover,
.altai-theme-admin .fc-state-hover,
.altai-theme-content #bodyView .fc-state-down,
.altai-theme-admin .fc-state-down,
.altai-theme-content #bodyView .fc-state-active,
.altai-theme-admin .fc-state-active,
.altai-theme-content #bodyView .fc-state-disabled,
.altai-theme-admin .fc-state-disabled {
  color: #333333;
  background-color: #e6e6e6;
}
.altai-theme-content #bodyView .fc-state-hover,
.altai-theme-admin .fc-state-hover {
  color: #333333;
  text-decoration: none;
  background-position: 0 -15px;
  -webkit-transition: background-position 0.1s linear;
  -moz-transition: background-position 0.1s linear;
  -o-transition: background-position 0.1s linear;
  transition: background-position 0.1s linear;
}
.altai-theme-content #bodyView .fc-state-down,
.altai-theme-admin .fc-state-down,
.altai-theme-content #bodyView .fc-state-active,
.altai-theme-admin .fc-state-active {
  background-color: #cccccc;
  background-image: none;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.15), 0 1px 2px rgba(0, 0, 0, 0.05);
}
.altai-theme-content #bodyView .fc-state-disabled,
.altai-theme-admin .fc-state-disabled {
  cursor: default;
  background-image: none;
  opacity: 0.65;
  filter: alpha(opacity=65);
  box-shadow: none;
}
.altai-theme-content #bodyView .fc-button-group,
.altai-theme-admin .fc-button-group {
  display: inline-block;
}
.altai-theme-content #bodyView .fc .fc-button-group > *,
.altai-theme-admin .fc .fc-button-group > * {
  /* extra precedence b/c buttons have margin set to zero */
  float: left;
  margin: 0 0 0 -1px;
}
.altai-theme-content #bodyView .fc .fc-button-group > :first-child,
.altai-theme-admin .fc .fc-button-group > :first-child {
  /* same */
  margin-left: 0;
}
.altai-theme-content #bodyView .fc-popover,
.altai-theme-admin .fc-popover {
  position: absolute;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.15);
}
.altai-theme-content #bodyView .fc-popover .fc-header,
.altai-theme-admin .fc-popover .fc-header {
  /* TODO: be more consistent with fc-head/fc-body */
  padding: 2px 4px;
}
.altai-theme-content #bodyView .fc-popover .fc-header .fc-title,
.altai-theme-admin .fc-popover .fc-header .fc-title {
  margin: 0 2px;
}
.altai-theme-content #bodyView .fc-popover .fc-header .fc-close,
.altai-theme-admin .fc-popover .fc-header .fc-close {
  cursor: pointer;
}
.altai-theme-content #bodyView .fc-ltr .fc-popover .fc-header .fc-title,
.altai-theme-admin .fc-ltr .fc-popover .fc-header .fc-title,
.altai-theme-content #bodyView .fc-rtl .fc-popover .fc-header .fc-close,
.altai-theme-admin .fc-rtl .fc-popover .fc-header .fc-close {
  float: left;
}
.altai-theme-content #bodyView .fc-rtl .fc-popover .fc-header .fc-title,
.altai-theme-admin .fc-rtl .fc-popover .fc-header .fc-title,
.altai-theme-content #bodyView .fc-ltr .fc-popover .fc-header .fc-close,
.altai-theme-admin .fc-ltr .fc-popover .fc-header .fc-close {
  float: right;
}
.altai-theme-content #bodyView .fc-unthemed .fc-popover,
.altai-theme-admin .fc-unthemed .fc-popover {
  border-width: 1px;
  border-style: solid;
}
.altai-theme-content #bodyView .fc-unthemed .fc-popover .fc-header .fc-close,
.altai-theme-admin .fc-unthemed .fc-popover .fc-header .fc-close {
  font-size: .9em;
  margin-top: 2px;
}
.altai-theme-content #bodyView .fc-popover > .ui-widget-header + .ui-widget-content,
.altai-theme-admin .fc-popover > .ui-widget-header + .ui-widget-content {
  border-top: 0;
  /* where they meet, let the header have the border */
}
.altai-theme-content #bodyView .fc-divider,
.altai-theme-admin .fc-divider {
  border-style: solid;
  border-width: 1px;
}
.altai-theme-content #bodyView hr.fc-divider,
.altai-theme-admin hr.fc-divider {
  height: 0;
  margin: 0;
  padding: 0 0 2px;
  /* height is unreliable across browsers, so use padding */
  border-width: 1px 0;
}
.altai-theme-content #bodyView .fc-clear,
.altai-theme-admin .fc-clear {
  clear: both;
}
.altai-theme-content #bodyView .fc-bg,
.altai-theme-admin .fc-bg,
.altai-theme-content #bodyView .fc-bgevent-skeleton,
.altai-theme-admin .fc-bgevent-skeleton,
.altai-theme-content #bodyView .fc-highlight-skeleton,
.altai-theme-admin .fc-highlight-skeleton,
.altai-theme-content #bodyView .fc-helper-skeleton,
.altai-theme-admin .fc-helper-skeleton {
  /* these element should always cling to top-left/right corners */
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.altai-theme-content #bodyView .fc-bg,
.altai-theme-admin .fc-bg {
  bottom: 0;
  /* strech bg to bottom edge */
}
.altai-theme-content #bodyView .fc-bg table,
.altai-theme-admin .fc-bg table {
  height: 100%;
  /* strech bg to bottom edge */
}
.altai-theme-content #bodyView .fc table,
.altai-theme-admin .fc table {
  width: 100%;
  table-layout: fixed;
  border-collapse: collapse;
  border-spacing: 0;
  font-size: 1em;
  /* normalize cross-browser */
}
.altai-theme-content #bodyView .fc th,
.altai-theme-admin .fc th {
  text-align: center;
}
.altai-theme-content #bodyView .fc th,
.altai-theme-admin .fc th,
.altai-theme-content #bodyView .fc td,
.altai-theme-admin .fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top;
}
.altai-theme-content #bodyView .fc td.fc-today,
.altai-theme-admin .fc td.fc-today {
  border-style: double;
  /* overcome neighboring borders */
}
.altai-theme-content #bodyView .fc .fc-row,
.altai-theme-admin .fc .fc-row {
  /* extra precedence to overcome themes w/ .ui-widget-content forcing a 1px border */
  /* no visible border by default. but make available if need be (scrollbar width compensation) */
  border-style: solid;
  border-width: 0;
}
.altai-theme-content #bodyView .fc-row table,
.altai-theme-admin .fc-row table {
  /* don't put left/right border on anything within a fake row.
	   the outer tbody will worry about this */
  border-left: 0 hidden transparent;
  border-right: 0 hidden transparent;
  /* no bottom borders on rows */
  border-bottom: 0 hidden transparent;
}
.altai-theme-content #bodyView .fc-row:first-child table,
.altai-theme-admin .fc-row:first-child table {
  border-top: 0 hidden transparent;
  /* no top border on first row */
}
.altai-theme-content #bodyView .fc-row,
.altai-theme-admin .fc-row {
  position: relative;
}
.altai-theme-content #bodyView .fc-row .fc-bg,
.altai-theme-admin .fc-row .fc-bg {
  z-index: 1;
}
.altai-theme-content #bodyView .fc-row .fc-bgevent-skeleton,
.altai-theme-admin .fc-row .fc-bgevent-skeleton,
.altai-theme-content #bodyView .fc-row .fc-highlight-skeleton,
.altai-theme-admin .fc-row .fc-highlight-skeleton {
  bottom: 0;
  /* stretch skeleton to bottom of row */
}
.altai-theme-content #bodyView .fc-row .fc-bgevent-skeleton table,
.altai-theme-admin .fc-row .fc-bgevent-skeleton table,
.altai-theme-content #bodyView .fc-row .fc-highlight-skeleton table,
.altai-theme-admin .fc-row .fc-highlight-skeleton table {
  height: 100%;
  /* stretch skeleton to bottom of row */
}
.altai-theme-content #bodyView .fc-row .fc-highlight-skeleton td,
.altai-theme-admin .fc-row .fc-highlight-skeleton td,
.altai-theme-content #bodyView .fc-row .fc-bgevent-skeleton td,
.altai-theme-admin .fc-row .fc-bgevent-skeleton td {
  border-color: transparent;
}
.altai-theme-content #bodyView .fc-row .fc-bgevent-skeleton,
.altai-theme-admin .fc-row .fc-bgevent-skeleton {
  z-index: 2;
}
.altai-theme-content #bodyView .fc-row .fc-highlight-skeleton,
.altai-theme-admin .fc-row .fc-highlight-skeleton {
  z-index: 3;
}
.altai-theme-content #bodyView .fc-row .fc-content-skeleton,
.altai-theme-admin .fc-row .fc-content-skeleton {
  position: relative;
  z-index: 4;
  padding-bottom: 2px;
  /* matches the space above the events */
}
.altai-theme-content #bodyView .fc-row .fc-helper-skeleton,
.altai-theme-admin .fc-row .fc-helper-skeleton {
  z-index: 5;
}
.altai-theme-content #bodyView .fc-row .fc-content-skeleton td,
.altai-theme-admin .fc-row .fc-content-skeleton td,
.altai-theme-content #bodyView .fc-row .fc-helper-skeleton td,
.altai-theme-admin .fc-row .fc-helper-skeleton td {
  /* see-through to the background below */
  background: none;
  /* in case <td>s are globally styled */
  border-color: transparent;
  /* don't put a border between events and/or the day number */
  border-bottom: 0;
}
.altai-theme-content #bodyView .fc-row .fc-content-skeleton tbody td,
.altai-theme-admin .fc-row .fc-content-skeleton tbody td,
.altai-theme-content #bodyView .fc-row .fc-helper-skeleton tbody td,
.altai-theme-admin .fc-row .fc-helper-skeleton tbody td {
  /* don't put a border between event cells */
  border-top: 0;
}
.altai-theme-content #bodyView .fc-scroller,
.altai-theme-admin .fc-scroller {
  -webkit-overflow-scrolling: touch;
}
.altai-theme-content #bodyView .fc-scroller > .fc-day-grid,
.altai-theme-admin .fc-scroller > .fc-day-grid,
.altai-theme-content #bodyView .fc-scroller > .fc-time-grid,
.altai-theme-admin .fc-scroller > .fc-time-grid {
  position: relative;
  /* re-scope all positions */
  width: 100%;
  /* hack to force re-sizing this inner element when scrollbars appear/disappear */
}
.altai-theme-content #bodyView .fc-event,
.altai-theme-admin .fc-event {
  position: relative;
  /* for resize handle and other inner positioning */
  display: block;
  /* make the <a> tag block */
  font-size: .85em;
  line-height: 1.3;
  border-radius: 3px;
  border: 1px solid #3a87ad;
  /* default BORDER color */
  background-color: #3a87ad;
  /* default BACKGROUND color */
  font-weight: normal;
  /* undo jqui's ui-widget-header bold */
}
.altai-theme-content #bodyView .fc-event,
.altai-theme-admin .fc-event,
.altai-theme-content #bodyView .fc-event:hover,
.altai-theme-admin .fc-event:hover,
.altai-theme-content #bodyView .ui-widget .fc-event,
.altai-theme-admin .ui-widget .fc-event {
  color: #fff;
  /* default TEXT color */
  text-decoration: none;
  /* if <a> has an href */
}
.altai-theme-content #bodyView .fc-event[href],
.altai-theme-admin .fc-event[href],
.altai-theme-content #bodyView .fc-event.fc-draggable,
.altai-theme-admin .fc-event.fc-draggable {
  cursor: pointer;
  /* give events with links and draggable events a hand mouse pointer */
}
.altai-theme-content #bodyView .fc-not-allowed,
.altai-theme-admin .fc-not-allowed,
.altai-theme-content #bodyView .fc-not-allowed .fc-event,
.altai-theme-admin .fc-not-allowed .fc-event {
  /* to override an event's custom cursor */
  cursor: not-allowed;
}
.altai-theme-content #bodyView .fc-event .fc-bg,
.altai-theme-admin .fc-event .fc-bg {
  /* the generic .fc-bg already does position */
  z-index: 1;
  background: #fff;
  opacity: .25;
  filter: alpha(opacity=25);
  /* for IE */
}
.altai-theme-content #bodyView .fc-event .fc-content,
.altai-theme-admin .fc-event .fc-content {
  position: relative;
  z-index: 2;
}
.altai-theme-content #bodyView .fc-event .fc-resizer,
.altai-theme-admin .fc-event .fc-resizer {
  position: absolute;
  z-index: 4;
}
.altai-theme-content #bodyView .fc-event .fc-resizer,
.altai-theme-admin .fc-event .fc-resizer {
  display: none;
}
.altai-theme-content #bodyView .fc-event.fc-allow-mouse-resize .fc-resizer,
.altai-theme-admin .fc-event.fc-allow-mouse-resize .fc-resizer,
.altai-theme-content #bodyView .fc-event.fc-selected .fc-resizer,
.altai-theme-admin .fc-event.fc-selected .fc-resizer {
  /* only show when hovering or selected (with touch) */
  display: block;
}
.altai-theme-content #bodyView .fc-event.fc-selected .fc-resizer:before,
.altai-theme-admin .fc-event.fc-selected .fc-resizer:before {
  /* 40x40 touch area */
  content: "";
  position: absolute;
  z-index: 9999;
  /* user of this util can scope within a lower z-index */
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
}
.altai-theme-content #bodyView .fc-event.fc-selected,
.altai-theme-admin .fc-event.fc-selected {
  z-index: 9999 !important;
  /* overcomes inline z-index */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}
.altai-theme-content #bodyView .fc-event.fc-selected.fc-dragging,
.altai-theme-admin .fc-event.fc-selected.fc-dragging {
  box-shadow: 0 2px 7px rgba(0, 0, 0, 0.3);
}
.altai-theme-content #bodyView .fc-h-event.fc-selected:before,
.altai-theme-admin .fc-h-event.fc-selected:before {
  content: "";
  position: absolute;
  z-index: 3;
  /* below resizers */
  top: -10px;
  bottom: -10px;
  left: 0;
  right: 0;
}
.altai-theme-content #bodyView .fc-ltr .fc-h-event.fc-not-start,
.altai-theme-admin .fc-ltr .fc-h-event.fc-not-start,
.altai-theme-content #bodyView .fc-rtl .fc-h-event.fc-not-end,
.altai-theme-admin .fc-rtl .fc-h-event.fc-not-end {
  margin-left: 0;
  border-left-width: 0;
  padding-left: 1px;
  /* replace the border with padding */
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.altai-theme-content #bodyView .fc-ltr .fc-h-event.fc-not-end,
.altai-theme-admin .fc-ltr .fc-h-event.fc-not-end,
.altai-theme-content #bodyView .fc-rtl .fc-h-event.fc-not-start,
.altai-theme-admin .fc-rtl .fc-h-event.fc-not-start {
  margin-right: 0;
  border-right-width: 0;
  padding-right: 1px;
  /* replace the border with padding */
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.altai-theme-content #bodyView .fc-ltr .fc-h-event .fc-start-resizer,
.altai-theme-admin .fc-ltr .fc-h-event .fc-start-resizer,
.altai-theme-content #bodyView .fc-rtl .fc-h-event .fc-end-resizer,
.altai-theme-admin .fc-rtl .fc-h-event .fc-end-resizer {
  cursor: w-resize;
  left: -1px;
  /* overcome border */
}
.altai-theme-content #bodyView .fc-ltr .fc-h-event .fc-end-resizer,
.altai-theme-admin .fc-ltr .fc-h-event .fc-end-resizer,
.altai-theme-content #bodyView .fc-rtl .fc-h-event .fc-start-resizer,
.altai-theme-admin .fc-rtl .fc-h-event .fc-start-resizer {
  cursor: e-resize;
  right: -1px;
  /* overcome border */
}
.altai-theme-content #bodyView .fc-h-event.fc-allow-mouse-resize .fc-resizer,
.altai-theme-admin .fc-h-event.fc-allow-mouse-resize .fc-resizer {
  width: 7px;
  top: -1px;
  /* overcome top border */
  bottom: -1px;
  /* overcome bottom border */
}
.altai-theme-content #bodyView .fc-h-event.fc-selected .fc-resizer,
.altai-theme-admin .fc-h-event.fc-selected .fc-resizer {
  /* 8x8 little dot */
  border-radius: 4px;
  border-width: 1px;
  width: 6px;
  height: 6px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  /* vertically center */
  top: 50%;
  margin-top: -4px;
}
.altai-theme-content #bodyView .fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
.altai-theme-admin .fc-ltr .fc-h-event.fc-selected .fc-start-resizer,
.altai-theme-content #bodyView .fc-rtl .fc-h-event.fc-selected .fc-end-resizer,
.altai-theme-admin .fc-rtl .fc-h-event.fc-selected .fc-end-resizer {
  margin-left: -4px;
  /* centers the 8x8 dot on the left edge */
}
.altai-theme-content #bodyView .fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
.altai-theme-admin .fc-ltr .fc-h-event.fc-selected .fc-end-resizer,
.altai-theme-content #bodyView .fc-rtl .fc-h-event.fc-selected .fc-start-resizer,
.altai-theme-admin .fc-rtl .fc-h-event.fc-selected .fc-start-resizer {
  margin-right: -4px;
  /* centers the 8x8 dot on the right edge */
}
.altai-theme-content #bodyView .fc-day-grid-event,
.altai-theme-admin .fc-day-grid-event {
  margin: 1px 2px 0;
  /* spacing between events and edges */
  padding: 0 1px;
}
.altai-theme-content #bodyView .fc-day-grid-event.fc-selected:after,
.altai-theme-admin .fc-day-grid-event.fc-selected:after {
  content: "";
  position: absolute;
  z-index: 1;
  /* same z-index as fc-bg, behind text */
  /* overcome the borders */
  top: -1px;
  right: -1px;
  bottom: -1px;
  left: -1px;
  /* darkening effect */
  background: #000;
  opacity: .25;
  filter: alpha(opacity=25);
  /* for IE */
}
.altai-theme-content #bodyView .fc-day-grid-event .fc-content,
.altai-theme-admin .fc-day-grid-event .fc-content {
  /* force events to be one-line tall */
  white-space: nowrap;
  overflow: hidden;
}
.altai-theme-content #bodyView .fc-day-grid-event .fc-time,
.altai-theme-admin .fc-day-grid-event .fc-time {
  font-weight: bold;
}
.altai-theme-content #bodyView .fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.altai-theme-admin .fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.altai-theme-content #bodyView .fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.altai-theme-admin .fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer {
  margin-left: -2px;
  /* to the day cell's edge */
}
.altai-theme-content #bodyView .fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.altai-theme-admin .fc-ltr .fc-day-grid-event.fc-allow-mouse-resize .fc-end-resizer,
.altai-theme-content #bodyView .fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer,
.altai-theme-admin .fc-rtl .fc-day-grid-event.fc-allow-mouse-resize .fc-start-resizer {
  margin-right: -2px;
  /* to the day cell's edge */
}
.altai-theme-content #bodyView a.fc-more,
.altai-theme-admin a.fc-more {
  margin: 1px 3px;
  font-size: .85em;
  cursor: pointer;
  text-decoration: none;
}
.altai-theme-content #bodyView a.fc-more:hover,
.altai-theme-admin a.fc-more:hover {
  text-decoration: underline;
}
.altai-theme-content #bodyView .fc-limited,
.altai-theme-admin .fc-limited {
  /* rows and cells that are hidden because of a "more" link */
  display: none;
}
.altai-theme-content #bodyView .fc-day-grid .fc-row,
.altai-theme-admin .fc-day-grid .fc-row {
  z-index: 1;
  /* make the "more" popover one higher than this */
}
.altai-theme-content #bodyView .fc-more-popover,
.altai-theme-admin .fc-more-popover {
  z-index: 2;
  width: 220px;
}
.altai-theme-content #bodyView .fc-more-popover .fc-event-container,
.altai-theme-admin .fc-more-popover .fc-event-container {
  padding: 10px;
}
.altai-theme-content #bodyView .fc-now-indicator,
.altai-theme-admin .fc-now-indicator {
  position: absolute;
  border: 0 solid red;
}
.altai-theme-content #bodyView .fc-unselectable,
.altai-theme-admin .fc-unselectable {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.altai-theme-content #bodyView .fc-toolbar,
.altai-theme-admin .fc-toolbar {
  text-align: center;
  margin-bottom: 1em;
}
.altai-theme-content #bodyView .fc-toolbar .fc-left,
.altai-theme-admin .fc-toolbar .fc-left {
  float: left;
}
.altai-theme-content #bodyView .fc-toolbar .fc-right,
.altai-theme-admin .fc-toolbar .fc-right {
  float: right;
}
.altai-theme-content #bodyView .fc-toolbar .fc-center,
.altai-theme-admin .fc-toolbar .fc-center {
  display: inline-block;
}
.altai-theme-content #bodyView .fc .fc-toolbar > * > *,
.altai-theme-admin .fc .fc-toolbar > * > * {
  /* extra precedence to override button border margins */
  float: left;
  margin-left: .75em;
}
.altai-theme-content #bodyView .fc .fc-toolbar > * > :first-child,
.altai-theme-admin .fc .fc-toolbar > * > :first-child {
  /* extra precedence to override button border margins */
  margin-left: 0;
}
.altai-theme-content #bodyView .fc-toolbar h2,
.altai-theme-admin .fc-toolbar h2 {
  margin: 0;
}
.altai-theme-content #bodyView .fc-toolbar button,
.altai-theme-admin .fc-toolbar button {
  position: relative;
}
.altai-theme-content #bodyView .fc-toolbar .fc-state-hover,
.altai-theme-admin .fc-toolbar .fc-state-hover,
.altai-theme-content #bodyView .fc-toolbar .ui-state-hover,
.altai-theme-admin .fc-toolbar .ui-state-hover {
  z-index: 2;
}
.altai-theme-content #bodyView .fc-toolbar .fc-state-down,
.altai-theme-admin .fc-toolbar .fc-state-down {
  z-index: 3;
}
.altai-theme-content #bodyView .fc-toolbar .fc-state-active,
.altai-theme-admin .fc-toolbar .fc-state-active,
.altai-theme-content #bodyView .fc-toolbar .ui-state-active,
.altai-theme-admin .fc-toolbar .ui-state-active {
  z-index: 4;
}
.altai-theme-content #bodyView .fc-toolbar button:focus,
.altai-theme-admin .fc-toolbar button:focus {
  z-index: 5;
}
.altai-theme-content #bodyView .fc-view-container *,
.altai-theme-admin .fc-view-container *,
.altai-theme-content #bodyView .fc-view-container *:before,
.altai-theme-admin .fc-view-container *:before,
.altai-theme-content #bodyView .fc-view-container *:after,
.altai-theme-admin .fc-view-container *:after {
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}
.altai-theme-content #bodyView .fc-view,
.altai-theme-admin .fc-view,
.altai-theme-content #bodyView .fc-view > table,
.altai-theme-admin .fc-view > table {
  /* so dragged elements can be above the view's main element */
  position: relative;
  z-index: 1;
}
.altai-theme-content #bodyView .fc-basicWeek-view .fc-content-skeleton,
.altai-theme-admin .fc-basicWeek-view .fc-content-skeleton,
.altai-theme-content #bodyView .fc-basicDay-view .fc-content-skeleton,
.altai-theme-admin .fc-basicDay-view .fc-content-skeleton {
  /* we are sure there are no day numbers in these views, so... */
  padding-top: 1px;
  /* add a pixel to make sure there are 2px padding above events */
  padding-bottom: 1em;
  /* ensure a space at bottom of cell for user selecting/clicking */
}
.altai-theme-content #bodyView .fc-basic-view .fc-body .fc-row,
.altai-theme-admin .fc-basic-view .fc-body .fc-row {
  min-height: 4em;
  /* ensure that all rows are at least this tall */
}
.altai-theme-content #bodyView .fc-row.fc-rigid,
.altai-theme-admin .fc-row.fc-rigid {
  overflow: hidden;
}
.altai-theme-content #bodyView .fc-row.fc-rigid .fc-content-skeleton,
.altai-theme-admin .fc-row.fc-rigid .fc-content-skeleton {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
.altai-theme-content #bodyView .fc-basic-view .fc-week-number,
.altai-theme-admin .fc-basic-view .fc-week-number,
.altai-theme-content #bodyView .fc-basic-view .fc-day-number,
.altai-theme-admin .fc-basic-view .fc-day-number {
  padding: 0 2px;
}
.altai-theme-content #bodyView .fc-basic-view td.fc-week-number span,
.altai-theme-admin .fc-basic-view td.fc-week-number span,
.altai-theme-content #bodyView .fc-basic-view td.fc-day-number,
.altai-theme-admin .fc-basic-view td.fc-day-number {
  padding-top: 2px;
  padding-bottom: 2px;
}
.altai-theme-content #bodyView .fc-basic-view .fc-week-number,
.altai-theme-admin .fc-basic-view .fc-week-number {
  text-align: center;
}
.altai-theme-content #bodyView .fc-basic-view .fc-week-number span,
.altai-theme-admin .fc-basic-view .fc-week-number span {
  /* work around the way we do column resizing and ensure a minimum width */
  display: inline-block;
  min-width: 1.25em;
}
.altai-theme-content #bodyView .fc-ltr .fc-basic-view .fc-day-number,
.altai-theme-admin .fc-ltr .fc-basic-view .fc-day-number {
  text-align: right;
}
.altai-theme-content #bodyView .fc-rtl .fc-basic-view .fc-day-number,
.altai-theme-admin .fc-rtl .fc-basic-view .fc-day-number {
  text-align: left;
}
.altai-theme-content #bodyView .fc-day-number.fc-other-month,
.altai-theme-admin .fc-day-number.fc-other-month {
  opacity: 0.3;
  filter: alpha(opacity=30);
  /* for IE */
  /* opacity with small font can sometimes look too faded
	   might want to set the 'color' property instead
	   making day-numbers bold also fixes the problem */
}
.altai-theme-content #bodyView .fc-agenda-view .fc-day-grid,
.altai-theme-admin .fc-agenda-view .fc-day-grid {
  position: relative;
  z-index: 2;
  /* so the "more.." popover will be over the time grid */
}
.altai-theme-content #bodyView .fc-agenda-view .fc-day-grid .fc-row,
.altai-theme-admin .fc-agenda-view .fc-day-grid .fc-row {
  min-height: 3em;
  /* all-day section will never get shorter than this */
}
.altai-theme-content #bodyView .fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton,
.altai-theme-admin .fc-agenda-view .fc-day-grid .fc-row .fc-content-skeleton {
  padding-top: 1px;
  /* add a pixel to make sure there are 2px padding above events */
  padding-bottom: 1em;
  /* give space underneath events for clicking/selecting days */
}
.altai-theme-content #bodyView .fc .fc-axis,
.altai-theme-admin .fc .fc-axis {
  /* .fc to overcome default cell styles */
  vertical-align: middle;
  padding: 0 4px;
  white-space: nowrap;
}
.altai-theme-content #bodyView .fc-ltr .fc-axis,
.altai-theme-admin .fc-ltr .fc-axis {
  text-align: right;
}
.altai-theme-content #bodyView .fc-rtl .fc-axis,
.altai-theme-admin .fc-rtl .fc-axis {
  text-align: left;
}
.altai-theme-content #bodyView .ui-widget td.fc-axis,
.altai-theme-admin .ui-widget td.fc-axis {
  font-weight: normal;
  /* overcome jqui theme making it bold */
}
.altai-theme-content #bodyView .fc-time-grid-container,
.altai-theme-admin .fc-time-grid-container,
.altai-theme-content #bodyView .fc-time-grid,
.altai-theme-admin .fc-time-grid {
  /* so slats/bg/content/etc positions get scoped within here */
  position: relative;
  z-index: 1;
}
.altai-theme-content #bodyView .fc-time-grid,
.altai-theme-admin .fc-time-grid {
  min-height: 100%;
  /* so if height setting is 'auto', .fc-bg stretches to fill height */
}
.altai-theme-content #bodyView .fc-time-grid table,
.altai-theme-admin .fc-time-grid table {
  /* don't put outer borders on slats/bg/content/etc */
  border: 0 hidden transparent;
}
.altai-theme-content #bodyView .fc-time-grid > .fc-bg,
.altai-theme-admin .fc-time-grid > .fc-bg {
  z-index: 1;
}
.altai-theme-content #bodyView .fc-time-grid .fc-slats,
.altai-theme-admin .fc-time-grid .fc-slats,
.altai-theme-content #bodyView .fc-time-grid > hr,
.altai-theme-admin .fc-time-grid > hr {
  /* the <hr> AgendaView injects when grid is shorter than scroller */
  position: relative;
  z-index: 2;
}
.altai-theme-content #bodyView .fc-time-grid .fc-content-col,
.altai-theme-admin .fc-time-grid .fc-content-col {
  position: relative;
  /* because now-indicator lives directly inside */
}
.altai-theme-content #bodyView .fc-time-grid .fc-content-skeleton,
.altai-theme-admin .fc-time-grid .fc-content-skeleton {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  right: 0;
}
.altai-theme-content #bodyView .fc-time-grid .fc-business-container,
.altai-theme-admin .fc-time-grid .fc-business-container {
  position: relative;
  z-index: 1;
}
.altai-theme-content #bodyView .fc-time-grid .fc-bgevent-container,
.altai-theme-admin .fc-time-grid .fc-bgevent-container {
  position: relative;
  z-index: 2;
}
.altai-theme-content #bodyView .fc-time-grid .fc-highlight-container,
.altai-theme-admin .fc-time-grid .fc-highlight-container {
  position: relative;
  z-index: 3;
}
.altai-theme-content #bodyView .fc-time-grid .fc-event-container,
.altai-theme-admin .fc-time-grid .fc-event-container {
  position: relative;
  z-index: 4;
}
.altai-theme-content #bodyView .fc-time-grid .fc-now-indicator-line,
.altai-theme-admin .fc-time-grid .fc-now-indicator-line {
  z-index: 5;
}
.altai-theme-content #bodyView .fc-time-grid .fc-helper-container,
.altai-theme-admin .fc-time-grid .fc-helper-container {
  /* also is fc-event-container */
  position: relative;
  z-index: 6;
}
.altai-theme-content #bodyView .fc-time-grid .fc-slats td,
.altai-theme-admin .fc-time-grid .fc-slats td {
  height: 1.5em;
  border-bottom: 0;
  /* each cell is responsible for its top border */
}
.altai-theme-content #bodyView .fc-time-grid .fc-slats .fc-minor td,
.altai-theme-admin .fc-time-grid .fc-slats .fc-minor td {
  border-top-style: dotted;
}
.altai-theme-content #bodyView .fc-time-grid .fc-slats .ui-widget-content,
.altai-theme-admin .fc-time-grid .fc-slats .ui-widget-content {
  /* for jqui theme */
  background: none;
  /* see through to fc-bg */
}
.altai-theme-content #bodyView .fc-time-grid .fc-highlight-container,
.altai-theme-admin .fc-time-grid .fc-highlight-container {
  /* a div within a cell within the fc-highlight-skeleton */
  position: relative;
  /* scopes the left/right of the fc-highlight to be in the column */
}
.altai-theme-content #bodyView .fc-time-grid .fc-highlight,
.altai-theme-admin .fc-time-grid .fc-highlight {
  position: absolute;
  left: 0;
  right: 0;
  /* top and bottom will be in by JS */
}
.altai-theme-content #bodyView .fc-ltr .fc-time-grid .fc-event-container,
.altai-theme-admin .fc-ltr .fc-time-grid .fc-event-container {
  /* space on the sides of events for LTR (default) */
  margin: 0 2.5% 0 2px;
}
.altai-theme-content #bodyView .fc-rtl .fc-time-grid .fc-event-container,
.altai-theme-admin .fc-rtl .fc-time-grid .fc-event-container {
  /* space on the sides of events for RTL */
  margin: 0 2px 0 2.5%;
}
.altai-theme-content #bodyView .fc-time-grid .fc-event,
.altai-theme-admin .fc-time-grid .fc-event,
.altai-theme-content #bodyView .fc-time-grid .fc-bgevent,
.altai-theme-admin .fc-time-grid .fc-bgevent {
  position: absolute;
  z-index: 1;
  /* scope inner z-index's */
}
.altai-theme-content #bodyView .fc-time-grid .fc-bgevent,
.altai-theme-admin .fc-time-grid .fc-bgevent {
  /* background events always span full width */
  left: 0;
  right: 0;
}
.altai-theme-content #bodyView .fc-v-event.fc-not-start,
.altai-theme-admin .fc-v-event.fc-not-start {
  /* events that are continuing from another day */
  /* replace space made by the top border with padding */
  border-top-width: 0;
  padding-top: 1px;
  /* remove top rounded corners */
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.altai-theme-content #bodyView .fc-v-event.fc-not-end,
.altai-theme-admin .fc-v-event.fc-not-end {
  /* replace space made by the top border with padding */
  border-bottom-width: 0;
  padding-bottom: 1px;
  /* remove bottom rounded corners */
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.altai-theme-content #bodyView .fc-time-grid-event,
.altai-theme-admin .fc-time-grid-event {
  overflow: hidden;
  /* don't let the bg flow over rounded corners */
}
.altai-theme-content #bodyView .fc-time-grid-event.fc-selected,
.altai-theme-admin .fc-time-grid-event.fc-selected {
  /* need to allow touch resizers to extend outside event's bounding box */
  /* common fc-selected styles hide the fc-bg, so don't need this anyway */
  overflow: visible;
}
.altai-theme-content #bodyView .fc-time-grid-event.fc-selected .fc-bg,
.altai-theme-admin .fc-time-grid-event.fc-selected .fc-bg {
  display: none;
  /* hide semi-white background, to appear darker */
}
.altai-theme-content #bodyView .fc-time-grid-event .fc-content,
.altai-theme-admin .fc-time-grid-event .fc-content {
  overflow: hidden;
  /* for when .fc-selected */
}
.altai-theme-content #bodyView .fc-time-grid-event .fc-time,
.altai-theme-admin .fc-time-grid-event .fc-time,
.altai-theme-content #bodyView .fc-time-grid-event .fc-title,
.altai-theme-admin .fc-time-grid-event .fc-title {
  padding: 0 1px;
}
.altai-theme-content #bodyView .fc-time-grid-event .fc-time,
.altai-theme-admin .fc-time-grid-event .fc-time {
  font-size: .85em;
  white-space: nowrap;
}
.altai-theme-content #bodyView .fc-time-grid-event.fc-short .fc-content,
.altai-theme-admin .fc-time-grid-event.fc-short .fc-content {
  /* don't wrap to second line (now that contents will be inline) */
  white-space: nowrap;
}
.altai-theme-content #bodyView .fc-time-grid-event.fc-short .fc-time,
.altai-theme-admin .fc-time-grid-event.fc-short .fc-time,
.altai-theme-content #bodyView .fc-time-grid-event.fc-short .fc-title,
.altai-theme-admin .fc-time-grid-event.fc-short .fc-title {
  /* put the time and title on the same line */
  display: inline-block;
  vertical-align: top;
}
.altai-theme-content #bodyView .fc-time-grid-event.fc-short .fc-time span,
.altai-theme-admin .fc-time-grid-event.fc-short .fc-time span {
  display: none;
  /* don't display the full time text... */
}
.altai-theme-content #bodyView .fc-time-grid-event.fc-short .fc-time:before,
.altai-theme-admin .fc-time-grid-event.fc-short .fc-time:before {
  content: attr(data-start);
  /* ...instead, display only the start time */
}
.altai-theme-content #bodyView .fc-time-grid-event.fc-short .fc-time:after,
.altai-theme-admin .fc-time-grid-event.fc-short .fc-time:after {
  content: "\000A0-\000A0";
  /* seperate with a dash, wrapped in nbsp's */
}
.altai-theme-content #bodyView .fc-time-grid-event.fc-short .fc-title,
.altai-theme-admin .fc-time-grid-event.fc-short .fc-title {
  font-size: .85em;
  /* make the title text the same size as the time */
  padding: 0;
  /* undo padding from above */
}
.altai-theme-content #bodyView .fc-time-grid-event.fc-allow-mouse-resize .fc-resizer,
.altai-theme-admin .fc-time-grid-event.fc-allow-mouse-resize .fc-resizer {
  left: 0;
  right: 0;
  bottom: 0;
  height: 8px;
  overflow: hidden;
  line-height: 8px;
  font-size: 11px;
  font-family: monospace;
  text-align: center;
  cursor: s-resize;
}
.altai-theme-content #bodyView .fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after,
.altai-theme-admin .fc-time-grid-event.fc-allow-mouse-resize .fc-resizer:after {
  content: "=";
}
.altai-theme-content #bodyView .fc-time-grid-event.fc-selected .fc-resizer,
.altai-theme-admin .fc-time-grid-event.fc-selected .fc-resizer {
  /* 10x10 dot */
  border-radius: 5px;
  border-width: 1px;
  width: 8px;
  height: 8px;
  border-style: solid;
  border-color: inherit;
  background: #fff;
  /* horizontally center */
  left: 50%;
  margin-left: -5px;
  /* center on the bottom edge */
  bottom: -5px;
}
.altai-theme-content #bodyView .fc-time-grid .fc-now-indicator-line,
.altai-theme-admin .fc-time-grid .fc-now-indicator-line {
  border-top-width: 1px;
  left: 0;
  right: 0;
}
.altai-theme-content #bodyView .fc-time-grid .fc-now-indicator-arrow,
.altai-theme-admin .fc-time-grid .fc-now-indicator-arrow {
  margin-top: -5px;
  /* vertically center on top coordinate */
}
.altai-theme-content #bodyView .fc-ltr .fc-time-grid .fc-now-indicator-arrow,
.altai-theme-admin .fc-ltr .fc-time-grid .fc-now-indicator-arrow {
  left: 0;
  /* triangle pointing right... */
  border-width: 5px 0 5px 6px;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.altai-theme-content #bodyView .fc-rtl .fc-time-grid .fc-now-indicator-arrow,
.altai-theme-admin .fc-rtl .fc-time-grid .fc-now-indicator-arrow {
  right: 0;
  /* triangle pointing left... */
  border-width: 5px 6px 5px 0;
  border-top-color: transparent;
  border-bottom-color: transparent;
}
.altai-theme-content #bodyView .fc div,
.altai-theme-admin .fc div {
  /*overflow-x: hidden !important;*/
  /*overflow-y: hidden !important;*/
}
.altai-theme-content #bodyView .fc .fc-scroller,
.altai-theme-admin .fc .fc-scroller {
  overflow-y: unset!important;
  overflow-x: unset!important;
}
.altai-theme-content #bodyView .fc .fc-toolbar,
.altai-theme-admin .fc .fc-toolbar {
  padding: 10px 0;
}
.altai-theme-content #bodyView .fc .fc-toolbar button,
.altai-theme-admin .fc .fc-toolbar button {
  box-shadow: none;
}
.altai-theme-content #bodyView .fc .fc-toolbar button:hover,
.altai-theme-admin .fc .fc-toolbar button:hover,
.altai-theme-content #bodyView .fc .fc-toolbar button:active,
.altai-theme-admin .fc .fc-toolbar button:active,
.altai-theme-content #bodyView .fc .fc-toolbar button:focus,
.altai-theme-admin .fc .fc-toolbar button:focus {
  font-weight: 900;
  color: black !important;
}
.altai-theme-content #bodyView .fc .fc-time,
.altai-theme-admin .fc .fc-time {
  font-size: 0.8em;
  color: inherit;
}
.altai-theme-content #bodyView .fc .fc-title,
.altai-theme-admin .fc .fc-title {
  font-size: 0.8em;
  color: inherit;
}
.altai-theme-content #bodyView .fc th,
.altai-theme-admin .fc th {
  background-color: black;
}
.altai-theme-content #bodyView .fc th,
.altai-theme-admin .fc th,
.altai-theme-content #bodyView .fc td,
.altai-theme-admin .fc td {
  border-style: solid;
  border-width: 1px;
  padding: 0;
  vertical-align: top;
  border-color: black;
}
.altai-theme-content #bodyView .fc td.fc-today,
.altai-theme-admin .fc td.fc-today {
  border-style: solid;
}
.altai-theme-content #bodyView .fc .fc-basic-view .fc-day-number,
.altai-theme-admin .fc .fc-basic-view .fc-day-number {
  text-align: right;
  font-weight: 900;
}
.altai-theme-content #bodyView .fc .fc-day-grid-event .fc-content,
.altai-theme-admin .fc .fc-day-grid-event .fc-content {
  white-space: normal;
  overflow: initial;
  border-radius: 0;
  padding: 4px;
  margin-bottom: 5px;
}
.altai-theme-content #bodyView .fc a.fc-day-grid-event,
.altai-theme-admin .fc a.fc-day-grid-event {
  border-radius: 0;
}
.altai-theme-content #bodyView .fc table tr:nth-child(even),
.altai-theme-admin .fc table tr:nth-child(even) {
  background: none;
}
.altai-theme-content #bodyView .fc-unthemed .fc-today,
.altai-theme-admin .fc-unthemed .fc-today {
  background: #EDEDED;
}
.altai-theme-content #bodyView .container-content .fc table a,
.altai-theme-admin .container-content .fc table a {
  border-bottom: 0 solid #b6e1ff;
}
